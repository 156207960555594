
// nfrost 21 scripts:

jQuery(document).ready(function() {

  // 1. back to top button:
  var offset = 250;
  var duration = 300;

  jQuery(window).scroll(function() {
    if (jQuery(this).scrollTop() > offset) {
      jQuery('.back-to-top').fadeIn(duration);
    } else {
      jQuery('.back-to-top').fadeOut(duration);
    }
  });

  jQuery('.back-to-top').click(function(event) {
    event.preventDefault();
    jQuery('html, body').animate({scrollTop:0}, duration);
    return false;
  });

  // 2. animate dropdown navigation menu
  jQuery('.dropdown').on('show.bs.dropdown', function(e){
    jQuery(this).find('.dropdown-menu').first().stop(true, true).slideDown("fast");
  });

  jQuery('.dropdown').on('hide.bs.dropdown', function(e){
    jQuery(this).find('.dropdown-menu').first().stop(true, true).slideUp("fast");
  });


  // 3. remove title attribute from navigation:
  jQuery('a').removeAttr('title');


  // 4. disable scroll on google maps
  jQuery('#map').addClass('scrolloff');          // set the mouse events to none when doc ready

  jQuery('#overlay').on("mouseup", function() {       // lock it when mouse up
    jQuery('#map').addClass('scrolloff');
  });
  jQuery('#overlay').on("mousedown", function() {    // when mouse down, set the mouse events free
    jQuery('#map').removeClass('scrolloff');
  });

  // 5. ----- NF. scroll to anchor - used on health page buttons
  // Select all links with hashes
  jQuery('a[href*=#]:not([href=#])').click(function() {
      if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') || location.hostname === this.hostname) {
          var target = jQuery(this.hash);
          target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
             if (target.length) {
               jQuery('html,body').animate({
                   scrollTop: target.offset().top
              }, 1000);
              return false;
          }
      }
  });

  // 7. Apply masonry layout to press page
  jQuery(window).load(function()
    {
    jQuery('.the-press').masonry({
      // options
      itemSelector: '.col-xs-12',
      // columnWidth: 200
    });
  });



  /*
  OFFER FORM DATEFIELDS
  */
  if(jQuery('.date1').length>0){

    var dateMin = decodeURIComponent(jQuery('#offerStartDateVal').val());
    var dateMax = decodeURIComponent(jQuery('#offerEndDateVal').val());
    var blackdays = jQuery('#offerBlockedDaysVal').val();//from 0 to 6 being 0 Sunday
    var stopChecks = false;//to stop loops on second calendar beforeShowDays
    //turn undefined to null
    dateMin = (dateMin === 'undefined' ? null : dateMin);
    dateMax = (dateMax === 'undefined' ? null : dateMax);

    if(!blackdays){
      blackdays = [99];//unblockable day
    }else{
      blackdays = blackdays.split(',').map(Number);//convert to array of integers
    }

    var dateMin2 = "";
    //blackDays = blackDays.split(",");


    //Global function for controlling dates
    /* BLock days of week */
    var blockBlackDays = function(date){
      var day = date.getDay();
      //window.console.log('day : ' + day+ ' > ' + jQuery.inArray(day,blackdays));
      if(stopChecks){stopChecks = false;dateMin2 = "";}//reset var
      return [(jQuery.inArray(day,blackdays)===-1?true:false)];//[(!blackDays.includes(day))];
    };

     /* BLock 2nd cal endate for first blocked day */

    var checkEndDate = function(date){
      var day = date.getDay();
      var showit = (jQuery.inArray(day,blackdays)===-1?true:false);
      //Once we reach a blocked day bloack rest of days
      if(!showit && !stopChecks){
          stopChecks = true;
          showit = false;
      }
      //console.log(day+'>'+showit);
      return [showit];
      //return [showit];//[(!blackDays.includes(day))];
    };

    var compareDate = function(str1){
      // str1 format should be dd/mm/yyyy. Separator can be anything e.g. / or -. It wont effect
      var dt1   = parseInt(str1.substring(0,2));
      var mon1  = parseInt(str1.substring(3,5));
      var yr1   = parseInt(str1.substring(6,10));
      var date1 = new Date(yr1, mon1-1, dt1);
      return date1;
    };

    jQuery('.date2').prop('disabled', true);
    //console.log(dateMin,dateMax);
    jQuery('.date1').datepicker({
        dateFormat: "dd/mm/yy",
        maxDate: dateMax,
        minDate: dateMin,
        firstDay: 1, // Start with Monday
        beforeShowDay: blockBlackDays,
        onSelect: function(date,instance){
          dateMin2 = compareDate(date);
          dateMin2.setDate(dateMin2.getDate()+1);
          jQuery('.date2').prop('disabled', false);
          jQuery('.date2').datepicker( "destroy" );
          jQuery('.date2').val('');
          jQuery('.date2').datepicker({
               dateFormat: "dd/mm/yy",
               maxDate: dateMax,
               minDate: dateMin2,
               firstDay: 1, // Start with Monday
               beforeShowDay: blockBlackDays,
          });
        }
    });
  }

  // final Press page - award panel becomes fixed position on scroll

  var elementPosition = jQuery('.content-sidebar').offset();
  //console.log(elementPosition.top);
  jQuery(window).scroll(function(){
      	var scrollTop = jQuery(window).scrollTop();
		//console.log(scrollTop);
		var head_height = (2*jQuery(".standard-text-block").height()) + jQuery(".img-banner").height() + 40;
		if(scrollTop > head_height) {
		    jQuery('.content-sidebar').css('position','fixed').css('top','0').css('margin-right','75px');
		} else {
		  	jQuery('.content-sidebar').css('position','static').css('margin-right', '0');
		}
  });
});


//Script to resize Iframe to fit it's contents
function resizeIFrameToFitContent( iFrame ) {

  iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
  iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
}

window.addEventListener('DOMContentLoaded', function(e) {

  var iFrame = document.getElementById( 'booking-iframe' );
  resizeIFrameToFitContent( iFrame );

  // or, to resize all iframes:
  // var iframes = document.querySelectorAll("iframe");
  // for( var i = 0; i < iframes.length; i++) {
  //     resizeIFrameToFitContent( iframes[i] );
  // }
} );

